<template>
  <b-card>
    <b-row class="my-1">
      <b-col cols="12">
        <h4 class="mb-2" v-if="consultant.name">
          {{ consultant.name }}
          <span
            v-if="consultant.role"
            class="badge badge-primary badge-pill"
            style="margin-left: 7px"
          >
            {{ consultant.role.name }}
          </span>
        </h4>
        <h5>Registro para líderes da campanha DNA Campeão 🎉</h5>
      </b-col>
    </b-row>
    <template v-if="loading.form">
      <b-row class="justify-content-md-center mt-2">
        <b-spinner big variant="primary" />
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12" md="3" class="mt-1">
          <b-form-group
            label="Missão Aprendiz (0 a 100)"
            label-for="apprentice"
          >
            <b-form-input
              id="apprentice"
              v-model="apprentice"
              type="number"
              :class="{ 'is-invalid': v$.apprentice.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.apprentice.$invalid || v$.apprentice.$invalid">
                Você deve informar um valor entre 0 e 100
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mt-1">
          <b-form-group label="Missão Focado (0 a 100)" label-for="focused">
            <b-form-input
              id="focused"
              v-model="focused"
              type="number"
              :class="{ 'is-invalid': v$.focused.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.focused.$invalid">
                Você deve informar um valor entre 0 e 100
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mt-1">
          <b-form-group
            label="Missão Protagonista (0 a 240)"
            label-for="protagonist"
          >
            <b-form-input
              id="protagonist"
              v-model="protagonist"
              type="number"
              :class="{ 'is-invalid': v$.protagonist.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.protagonist.$invalid">
                Você deve informar um valor entre 0 e 240
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mt-1">
          <b-form-group label="Missão Campeão (0 a 50)" label-for="champion">
            <b-form-input
              id="champion"
              v-model="champion"
              type="number"
              :class="{ 'is-invalid': v$.champion.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.champion.$invalid">
                Você deve informar um valor entre 0 e 50
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" class="mt-1">
          <b-badge variant="light-warning" class="p-1"> 
            ATENÇÃO: Preencha este formulário até o dia 03 do mês para que a pontuação seja associada ao mês anterior
          </b-badge>
        </b-col>
      </b-row>
      <div class="d-flex mt-1 justify-content-end">
        <b-button
          :disabled="saving"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="
            $router.push({
              name: 'consultant-dna-campaign',
              params: { id },
            })
          "
          class="lead-buttons"
        >
          Cancelar
        </b-button>
        <b-button
          :disabled="saving"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="lead-buttons ml-2"
          @click="onSubmit"
        >
          {{ saving ? "Salvando..." : "Salvar" }}
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import { minValue, maxValue } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import vSelect from "vue-select";
import { VMoney } from "v-money";
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as accountTypes from "@/modules/account/store/types";
import consultant from "@/router/routes/consultant";

export default {
  components: {
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    BBadge,
    vSelect,
  },
  props: ["id"],
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      consultant: {},
      saving: false,
      loading: {
        form: false,
      },
      apprentice: 0,
      focused: 0,
      protagonist: 0,
      champion: 0,
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      apprentice: { minValue: minValue(0), maxValue: maxValue(100) },
      focused: { minValue: minValue(0), maxValue: maxValue(100) },
      protagonist: { minValue: minValue(0), maxValue: maxValue(240) },
      champion: { minValue: minValue(0), maxValue: maxValue(50) },
    };
  },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
    }),
  },
  mounted() {
    this.getConsultant(this.id).then((response) => {
      this.consultant = response.data;
    });
    this.getData();
  },
  methods: {
    ...mapActions({
      getConsultant: sharedTypes.GET_CONSULTANT,
      getDnaCampaign: types.GET_DNA_CAMPAIGN,
      saveDnaCampaign: types.SAVE_DNA_CAMPAIGN,
    }),
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    getData() {
      this.loading.form = true;
      this.getDnaCampaign({
        consultant_id: this.id,
      })
        .then((response) => {
          if (response && response.data) {
            const { apprentice, focused, protagonist, champion } =
              response.data;

            this.apprentice = apprentice || 0;
            this.focused = focused || 0;
            this.protagonist = protagonist || 0;
            this.champion = champion || 0;
          }
        })
        .catch(() => {
          this.clear();
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const { apprentice, focused, protagonist, champion } = this;
      this.saveDnaCampaign({
        consultant_id: parseInt(this.consultant.id),
        apprentice: parseInt(apprentice || "0"),
        focused: parseInt(focused || "0"),
        protagonist: parseInt(protagonist || "0"),
        champion: parseInt(champion || "0"),
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Campanha salva com sucesso.",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push({
            name: "consultant-dna-campaign",
            params: { id: this.id },
          });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar a campanha. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
